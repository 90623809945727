import { CountUp } from "./plugins/countUp.min.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";
import { swiperObserver } from "./meta-settings.js";

document.addEventListener("DOMContentLoaded", function () {
	AOS.init({
		startEvent: "load",
		offset: 150,
		delay: 0,
		duration: 600,
		easing: "ease",
		once: true,
	});

	/*fix for safari 14*/
	let userAgent = navigator.userAgent.toLowerCase();
    let safari14 = /version\/(14|15).*safari/gi.test(userAgent);
	if(safari14){
		$('body').addClass('safari-14-support');
	}


	// sticky
	function stickyMenu() {
		const headerElem = document.querySelector(".header");

		if (scrollY >= 10) {
			headerElem.classList.add("sticky");
		} else {
			headerElem.classList.remove("sticky");
		}
	}

	window.addEventListener("scroll", stickyMenu);
	stickyMenu();
	// end sticky

	// header
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
	window.addEventListener("resize", () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	});

	const burgerMenu = (menuSelector, burgerSelector) => {
		const menuElem = document.querySelector(menuSelector),
			layoutElem = document.querySelector(".header__layout"),
			burgerElem = document.querySelector(burgerSelector);

		const removeClass = () => {
			menuElem.classList.remove("active");
			scrollLock.enablePageScroll();
		};
		removeClass();

		menuElem.querySelectorAll("a").forEach((i) => {
			i.addEventListener("click", (e) => {
				if (menuElem.classList.contains("active")) {
					removeClass();
				}
			});
		});

		burgerElem.addEventListener("click", () => {
			if (menuElem.classList.contains("active")) {
				removeClass();
				document.querySelector(".hero__bg").classList.remove("fixed-fix");
			} else {
				menuElem.classList.add("active");
				var method = "margin"; //Available methods: none, padding, margin, width, max-width
				scrollLock.setFillGapMethod(method);
				scrollLock.disablePageScroll();

				scrollLock.addFillGapSelector(".header__navbar-box");
				scrollLock.addScrollableSelector(".simplebar-content-wrapper");
				document.querySelector(".hero__bg").classList.add("fixed-fix");
			}
		});

		layoutElem.addEventListener("click", () => {
			removeClass();
		});
	};

	if (document.querySelector(".header")) {
		burgerMenu(".header", ".header__burger");
	}
	// end header

	// countUp
	let countUpArr = new Array();

	function CountUpFunction(id, finalNum, option = {}) {
		let defaultOptions = {
			separator: "",
			enableScrollSpy: true,
			scrollSpyRunOnce: true,
		};

		let mergeOptions = { ...defaultOptions, ...option };
		countUpArr.push(new CountUp(id, finalNum, mergeOptions));
	}

	if (document.querySelector(".work__counter")) {
		const counters = document.querySelectorAll(".work__num");
		counter(counters);
	}

	function counter(elements) {
		elements.forEach((counter, i) => {
			const value = counter.getAttribute("data-count");
			CountUpFunction(counter, value, { suffix: "" });
		});
	}
	// end countUp

	// gsap
	if (document.getElementsByClassName("scroll-line")[0]) {
		gsap.registerPlugin(ScrollTrigger);
		let speed = 9;
		if (window.innerWidth > 1366) {
			speed = 12;
		}
		let tl = gsap.timeline();

		const scrollingLineImage = document.querySelectorAll(".scroll-line");
		if (scrollingLineImage.length >= 1) {
			scrollingLineImage.forEach((text, i) => {
				let val;
				if (i % 2 !== 0) {
					val = speed;
				} else {
					val = -speed;
				}

				/*if (i === 3) {
                    val = -80;
                }*/

				/*gsap.set(text, {rotation: 17.1});*/

				let xOffset = val * Math.cos((17.1 * Math.PI) / 180);
				let yOffset = val * Math.sin((17.1 * Math.PI) / 180);

				tl.to(text, {
					xPercent: `+=${xOffset}`,
					yPercent: `+=${yOffset}`,
					/*rotation: 0,*/
					scrollTrigger: {
						trigger: text,
						scrub: 0,
					},
				});
			});
		}
	}

	// show more
	function showMore() {
		const showBtn = document.querySelector(".testimonials__btn");
		const showBtnText = showBtn.querySelector(".text");
		const showWrapper = document.querySelector(".testimonials__show-box");
		const contentWrapper = document.querySelector(".testimonials__card-box");
		const cards = document.querySelectorAll(".testimonials__card");

		cards.forEach((card) => {
			card.addEventListener("mouseenter", () => {
				cards.forEach((card) => {
					card.classList.remove("active");
				});
				card.classList.add("active");
			});
		});

		showBtn.addEventListener("click", () => {
			if (showWrapper.classList.contains("active")) {
				showWrapper.classList.remove("active");
				contentWrapper.classList.remove("active");
				showBtnText.innerHTML = "Show more";
				return;
			}
			showWrapper.classList.add("active");
			contentWrapper.classList.add("active");
			/*showBtnText.innerHTML = 'Show less';*/
			showBtn.style.display = "none";
		});
	}
	if (document.querySelector(".testimonials__btn")) {
		showMore();
	}

	// end show more

	// masonry
	//   for an individual element
	if (document.querySelector(".testimonials__card-box")) {
		const msnry = new Masonry(".testimonials__card-box", {
			itemSelector: ".testimonials__card",
		});

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						msnry.reloadItems();
					}
				});
			},
			{
				root: null,
				rootMargin: "0px",
				threshold: 0.1,
			}
		);

		observer.observe(document.querySelector(".testimonials__card-box"));
	}

	// end masonry

	// choices
	window.initChoices = function initChoices() {
		let choicesSelect = document.querySelectorAll(".js-choices");
		if (choicesSelect) {
			choicesSelect.forEach((input) => {
				if(!input.classList.contains('is-start')) {
					input.classList.add('is-start');

					const select = new Choices(input, {
						allowHTML: true,
						searchEnabled: true,
					});

				}

			});
		}
		if (document.querySelector(".choices")) {
			const choicesWrappers = document.querySelectorAll(".choices");

			choicesWrappers.forEach((choiceWrap) => {
				if(!choiceWrap.querySelector('select').classList.contains('is-start-simplebar')) {
					choiceWrap.querySelector('select').classList.add('is-start-simplebar')
					choiceWrap.addEventListener("click", () => {
						if (!choiceWrap.querySelector(".custom-simplebar-wrapper")) {
							const selectSimplebar = choiceWrap.querySelector(
									".choices__list--dropdown .choices__list"
							);
							const div = document.createElement("div");
							div.classList.add("custom-simplebar-wrapper");
							const content = selectSimplebar.innerHTML;
							selectSimplebar.innerHTML = "";
							div.setAttribute("data-simplebar", "");
							div.innerHTML = content;
							selectSimplebar.appendChild(div);
						}
					});
				}
			});
		}
	}

	initChoices();

	// end choices

	// marquee
	if (document.getElementsByClassName("marquee-content")[0]) {
		const marqueLineArr = document.querySelectorAll(".marquee-content");
		marqueLineArr.forEach((line, i) => {
			const imagesArr = line.querySelectorAll(".gallery__image");
			imagesArr.forEach((imageElem) => {
				const clone = imageElem.cloneNode(true);
				line.appendChild(clone);
			});
			imagesArr.forEach((imageElem) => {
				const clone = imageElem.cloneNode(true);
				line.appendChild(clone);
			});
		});

		for (let i = 0; i < marqueLineArr.length; i++) {
			if (i % 2) {
				const $mq = $(marqueLineArr[i]).marquee({
					duration: 70000,
					duplicated: true,
					delayBeforeStart: 100,
					startVisible: true,
					direction: "right",
				});

				if (window.innerWidth <= 1024) {
					marqueLineArr[i].addEventListener("click", () => {
						$mq.marquee("toggle");
					});
				}
			} else {
				const $mq = $(marqueLineArr[i]).marquee({
					duration: 70000,
					duplicated: true,
					delayBeforeStart: 100,
					startVisible: true,
					direction: "left",
				});

				if (window.innerWidth <= 1024) {
					marqueLineArr[i].addEventListener("click", () => {
						$mq.marquee("toggle");
					});
				}
			}
		}

		marqueLineArr.forEach((mq) => {
			mq.addEventListener("mouseenter", () => {
				/*marqueLineArr.forEach((mq, i) => {
					$(mq).marquee("pause");
				});*/
				$(mq).marquee("pause");
			});

			mq.addEventListener("mouseleave", () => {
				/*marqueLineArr.forEach((mq, i) => {
					$(mq).marquee("resume");
				});*/
				$(mq).marquee("resume");
			});
		});
	}
	// end marquee

	// swiper

	const swiperOnResize = (
		breakpoint,
		swiperClass,
		swiperSettings,
		startOnView
	) => {
		let swiper;

		breakpoint = window.matchMedia(breakpoint);

		const enableSwiper = function (className, settings) {
			swiper = new Swiper(className, settings);

			if (startOnView) {
				swiperObserver(swiper);
			}
		};

		const checker = function () {
			if (breakpoint.matches) {
				return enableSwiper(swiperClass, swiperSettings);
			} else {
				if (swiper !== undefined) swiper.destroy(true, true);
				return;
			}
		};

		breakpoint.addEventListener("change", checker);
		checker();
	};

	if (document.querySelector('.expect-section__list')) {
		swiperOnResize('(max-width: 1023px)', '.expect-section__list',
			{
				slidesPerView: 1,
				spaceBetween: 12,
				speed: 500,
				autoplay: {
					delay: 4000,
					disableOnInteraction: false,
					pauseOnMouseEnter: true,
				},
				navigation: {
					prevEl: ".expect-section .swiper-prev-btn",
					nextEl: ".expect-section .swiper-next-btn",
				},
				breakpoints: {
					600: {
						slidesPerView: 2,
					},
					768: {
						slidesPerView: 3,
					},
				},
			}
		);
	}


	if (document.querySelector(".jobs__slider")) {
		const jobsSlider = document.querySelector(".jobs__slider");
		const jobsSlidesArr = jobsSlider.querySelectorAll(".swiper-slide");

		let loop = false;
		let loopedSlides = false;

		if (jobsSlidesArr.length > 4) {
			loop = true;
			loopedSlides = jobsSlidesArr.length * 2;
		}

		new Swiper(".jobs__slider", {
			slidesPerView: 1,
			spaceBetween: 12,
			loop: loop,
			loopedSlides: loopedSlides,
			centeredSlides: true,
			navigation: {
				prevEl: ".jobs__prev",
				nextEl: ".jobs__next",
			},
			autoplay: {
				delay: 3000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			breakpoints: {
				569: {
					spaceBetween: 15,
					slidesPerView: 2,
				},
				1023: {
					spaceBetween: 15,
					slidesPerView: 3,
				},
			},
		});

		if (document.getElementsByClassName('jobs__card')[0]) {
			const cards = document.querySelectorAll('.jobs__card');
			cards.forEach(card => {
				setTimeout(function () {
					card.style.opacity = '1';

					const title = card.querySelector('.jobs__name');
					const titleHeight = card.querySelector('.jobs__name-sr').clientHeight;
					const descr = card.querySelector('.jobs__descr');

					if(descr) {
						console.log(titleHeight)
						console.log(title.clientHeight)

						let lineClamp = 4;

						if (title.clientHeight >= titleHeight * 2.4) {
							lineClamp = 2;
						} else if (title.clientHeight >= titleHeight * 1.4) {
							lineClamp = 3;
						} else {
							lineClamp = 4;
						}

						descr.style.webkitLineClamp = lineClamp;
					}
				}, 400);
			});
		}
	}

	if (document.querySelector(".work__slider") && innerWidth < 1024) {
		new Swiper(".work__slider", {
			slidesPerView: 1.3,
			spaceBetween: 12,
			navigation: {
				prevEl: ".work__prev",
				nextEl: ".work__next",
			},
			autoplay: {
				delay: 3000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			breakpoints: {
				569: {
					spaceBetween: 15,
					slidesPerView: 2,
				},
				1023: {
					spaceBetween: 15,
					slidesPerView: 3,
				},
			},
		});
	}

	if (document.querySelector(".our-values__slider")) {
		const ourValuesSwiper = new Swiper(".our-values__slider", {
			slidesPerView: 1,
			spaceBetween: 12,
			navigation: {
				prevEl: ".our-values__prev",
				nextEl: ".our-values__next",
			},
			autoplay: {
				delay: 3000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			breakpoints: {
				374: {
					spaceBetween: 10,
					slidesPerView: 2,
				},
				569: {
					spaceBetween: 15,
					slidesPerView: 3,
				},
				901: {
					spaceBetween: 15,
					slidesPerView: 4,
				},
			},
		});

		swiperObserver(ourValuesSwiper);
	}

	if (document.querySelector(".our-team__slider")) {
		const teamSlider = document.querySelector('.our-team__slider');
		const teamSlidesArr = document.querySelectorAll('.our-team__slider .swiper-slide');

		let loop = false;
		let loopedSlides = false;
		let slidesPerViewDesk = 3;
		let spaceBetweenDesk = 30;

		if(teamSlidesArr.length > 3) {
			loop = true;
			loopedSlides = teamSlidesArr.length * 4;
			slidesPerViewDesk  = 2.76;
			spaceBetweenDesk = 48;
		}

		let breakpoints = {
			374: {
				spaceBetween: 16,
				slidesPerView: 1,
			},
			421: {
				spaceBetween: 16,
				slidesPerView: 1,
			},
			551: {
				spaceBetween: 16,
				slidesPerView: 1.5,
			},
			901: {
				spaceBetween: 20,
				slidesPerView: 2.3,
			},
			1200: {
				spaceBetween: spaceBetweenDesk,
				slidesPerView: slidesPerViewDesk,
			},
		}

		if(teamSlider.classList.contains('executive-type')) {
			slidesPerViewDesk  = 3;
			spaceBetweenDesk = 24;

			loop = false;
			loopedSlides = false;

			breakpoints = {
				374: {
					spaceBetween: 16,
					slidesPerView: 1,
				},
				421: {
					spaceBetween: 16,
					slidesPerView: 1,
				},
				551: {
					spaceBetween: 16,
					slidesPerView: 1.5,
				},
				651: {
					spaceBetween: 16,
					slidesPerView: 2,
				},
				901: {
					spaceBetween: 20,
					slidesPerView: 2.5,
				},
				1200: {
					spaceBetween: spaceBetweenDesk,
					slidesPerView: slidesPerViewDesk,
				},
			}
		}

		const teamSwiper = new Swiper(".our-team__slider", {
			slidesPerView: 1,
			spaceBetween: 16,
			loop: loop,
			loopedSlides: loopedSlides,
			navigation: {
				prevEl: ".our-team__prev",
				nextEl: ".our-team__next",
			},
			autoplay: {
				delay: 3000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			breakpoints: breakpoints,
		});

		swiperObserver(teamSwiper);
	}

	const teamsCards = document.querySelectorAll('.team-card');
	if(teamsCards.length > 0) {
		teamsCards.forEach((card) => {
			const text = card.querySelector('.team-card__text');
			if(text) {
				setTimeout(function () {
					const scrollSimplebar = new SimpleBar(text);
				}, 50);

			}
		})
	}

	const ourClientsSlider = document.getElementsByClassName(
		"our-clients__sliders-container"
	)[0];
	if (ourClientsSlider) {
		const imageSlider = ourClientsSlider.getElementsByClassName(
			"our-clients__image-slider"
		)[0];
		const textSlider = ourClientsSlider.getElementsByClassName(
			"our-clients__slider"
		)[0];

		const imageSwiper = new Swiper(imageSlider, {
			slidesPerView: 1,
			spaceBetween: 10,
			effect: "fade",
			fadeEffect: {
				crossFade: true,
			},
		});

		const textSwiper = new Swiper(textSlider, {
			slidesPerView: 1,
			spaceBetween: 10,
			effect: "creative",
			creativeEffect: {
				prev: {
					translate: ["-20%", 0, -500],
					opacity: 0,
				},
				next: {
					translate: ["20%", 0, -600],
					opacity: 0,
				},
			},
			navigation: {
				prevEl: ".our-clients__slider-btn--prev",
				nextEl: ".our-clients__slider-btn--next",
			},
			autoplay: {
				delay: 3000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
		});

		imageSwiper.controller.control = textSwiper;
		textSwiper.controller.control = imageSwiper;

		if(window.innerWidth >= 768) {
			swiperObserver(textSwiper);
		}
	}

	const specialismSlider = document.getElementsByClassName(
		"our-specialism__slider"
	)[0];

	if (specialismSlider) {
		const slidesArr = specialismSlider.querySelectorAll('.swiper-slide');

		let loop = false;
		let loopedSlides = false;

		if(slidesArr.length >= 3) {
			loop = true;
			loopedSlides = slidesArr.length * 3;
		}

		const specialismSwiper = new Swiper(".our-specialism__slider", {
			slidesPerView: 1,
			spaceBetween: 0,
			loop: loop,
			loopedSlides: loopedSlides,
			threshold: 10,
			speed: 600,
			grabCursor: true,
			autoplay: {
				delay: 3500,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			effect: "creative",
			creativeEffect: {
				limitProgress: 8,
				prev: {
					effect: "scale",
					scale: 0.77,
					translate: ["calc(-100% + 6%)", 0, 1],
					opacity: 1,
				},
				next: {
					effect: "scale",
					scale: 0.77,
					translate: ["calc(100% - 6%)", 0, 1],
					opacity: 1,
				},
			},
			navigation: {
				prevEl: ".our-specialism__prev",
				nextEl: ".our-specialism__next",
			},
		});

		if(slidesArr.length >= 3 && window.innerWidth >= 1024) {
			specialismSwiper.slideTo(1);
		}

		if(specialismSwiper.autoplay.running){
			swiperObserver(specialismSwiper);
		}
	}

	const processSlider = document.getElementsByClassName(
		"our-process__slider"
	)[0];

	if (processSlider) {
		const processImagesSlider = document.getElementsByClassName(
			"our-process__images"
		)[0];

		let effect = "slide";
		let creativeEffect = false;
		let duration = 500;

		if (window.innerWidth < 652) {
			effect = "creative";
			creativeEffect = {
				limitProgress: 8,
				prev: {
					effect: "scale",
					scale: 0.77,
					translate: ["calc(-100% - 6%)", 0, 1],
					opacity: 0,
				},
				next: {
					effect: "scale",
					scale: 0.77,
					translate: ["calc(100% - 10%)", 0, 1],
					opacity: 0,
				},
			};
		}

		const processSwiper = new Swiper(".our-process__slider", {
			slidesPerView: 1,
			spaceBetween: 10,
			slideToClickedSlide: true,
			speed: duration,
			/*mousewheel: {
				forceToAxis: true,
				sensitivity: 1,
				releaseOnEdges: true,
			},*/
			pagination: {
				el: '.our-process-swiper-pagination',
				type: 'bullets',
			},
			grabCursor: true,
			effect: effect,
			creativeEffect: creativeEffect,

			navigation: {
				prevEl: ".our-process__prev",
				nextEl: ".our-process__next",
			},

			breakpoints: {
				652: {
					effect: "slide",
					creativeEffect: false,
					spaceBetween: 30,
					direction: "vertical",
					grid: {
						rows: 1,
						column: 1,
					},
				},
				901: {
					effect: "slide",
					creativeEffect: false,
					spaceBetween: 40,
					direction: "vertical",
					grid: {
						rows: 1,
						column: 1,
					},
				},
				1150: {
					effect: "slide",
					creativeEffect: false,
					spaceBetween: 72,
					direction: "vertical",
					grid: {
						rows: 1,
						column: 1,
					},
				},
			},
			on:{
				slideChange: function(swiper){
					let slides = swiper.el.querySelectorAll('.swiper-slide');
					let activeIndex = swiper.activeIndex;
					slides.forEach((slide, idx)=>{
							if(idx != activeIndex){
								slide.classList.remove('ovy-auto')
							}else{
								setTimeout(function(){
									slide.classList.add('ovy-auto');
								}, duration)
							}
					})
				}
			}
		});

		swiperObserver(processSwiper);

		const imageSwiper = new Swiper(processImagesSlider, {
			slidesPerView: 1,
			spaceBetween: 10,
			effect: "fade",
			fadeEffect: {
				crossFade: true,
			},
		});

		imageSwiper.controller.control = processSwiper;
		processSwiper.controller.control = imageSwiper;

		if (window.innerWidth >= 652) {
			processSwiper.activeIndex = 1;
		}

		/*swiperObserver(processSwiper);*/

		/*const activeSlide = document.querySelector(
			".our-process__slider .swiper-slide-active .our-process__item-text"
		);
		new SimpleBar(activeSlide);
		console.log(activeSlide);*/
	}

	const newsCategory = document.getElementsByClassName('news-category')[0];

	if(newsCategory) {

		const newsCategoriesArr = newsCategory.querySelectorAll('.news-category__btn');
		newsCategoriesArr.forEach((btn)  => {
			btn.addEventListener('click', () => {
				for (let i = 0; i < newsCategoriesArr.length; i++) {
					newsCategoriesArr[i].classList.remove('active');
					newsCategoriesArr[i].classList.remove('prev-active');
				}
				btn.classList.add('active');

				if(btn.previousElementSibling) {
					btn.previousElementSibling.classList.add('prev-active');
				}
			})
		})

		const newsSwiper = new Swiper(".news-category", {
			slidesPerView: 'auto',
		});
	}

	// end swiper

	const defaultCustomSelect = document.getElementsByClassName(
		"default-custom-select"
	)[0];
	if (defaultCustomSelect) {
		const defaultCustomSelectArr = document.querySelectorAll(
			".default-custom-select"
		);
		defaultCustomSelectArr.forEach((customSelect, i) => {
			const customSelectPlaceholder = customSelect.querySelector(
				".default-custom-select__placeholder"
			);
			const customSelectList = customSelect.querySelector(
				".default-custom-select__list"
			);
			const customSelectPlaceholderTextContent = customSelect.querySelector(
				".default-custom-select__placeholder"
			).textContent;

			customSelect.addEventListener("click", (e) => {
				if (!customSelect.classList.contains("active")) {
					customSelect.classList.add("active");
					console.log(customSelect);
				} else {
					if (!e.target.classList.contains("default-custom-select__list")) {
						customSelect.classList.remove("active");
					}
				}
			});

			document.addEventListener("click", (event) => {
				if (
					!customSelectList.contains(event.target) &&
					!customSelect.contains(event.target)
				) {
					customSelect.classList.remove("active");
				}
			});

			const checkboxesSelect = customSelect.querySelectorAll(
				'input[type="checkbox"]'
			);

			function placeholderCheck() {
				const checkedCount = customSelect.querySelectorAll(
					'input[type="checkbox"]:checked'
				).length;
				console.log(
					customSelect.querySelectorAll('input[type="checkbox"]:checked')
				);

				if (checkedCount === 1) {
					const checkedItem = customSelect.querySelector(
						'input[type="checkbox"]:checked'
					);
					customSelectPlaceholder.textContent =
						checkedItem.nextElementSibling.nextElementSibling.textContent;
				} else {
					customSelectPlaceholder.textContent =
						checkedCount > 0
							? `${checkedCount} items selected`
							: customSelectPlaceholderTextContent;
				}
			}

			checkboxesSelect.forEach((checkbox) => {
				checkbox.addEventListener("change", () => {
					placeholderCheck();
				});
			});

			const checkboxes = document.querySelectorAll('input[type="checkbox"]');
			for (let j = 0; j < checkboxes.length; j++) {
				checkboxes[j].addEventListener("change", () => {
					placeholderCheck();
				});
			}
		});
	}

	function isSafari() {
		return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	}

	if (isSafari()) {
		const mainElement = document.querySelector(".main");
		const footerElement = document.querySelector(".footer");

		if (mainElement) {
			mainElement.classList.add("is-safari");
		}

		if (footerElement) {
			footerElement.classList.add("is-safari");
		}
	}


	const singleRangeSlider = () => {
		const singleRange = document.getElementsByClassName(
			"single-range-default"
		)[0];
		if (singleRange) {
			const input = document.querySelector('.single-range-hidden-input');
			const slider = singleRange.querySelector("#single-range-default");
			const max = +slider.dataset.max;
			const min = +slider.dataset.min;
			const unit = slider.dataset?.unit || "£";
			const step = +slider.dataset.step;
			const inputsHidden = singleRange.querySelector(
				".single-range-hidden-input"
			);
			const inputInfo = singleRange.querySelector(".single-range-info");
			const startValue = +inputsHidden.value;

			noUiSlider.create(slider, {
				start: [startValue],
				connect: "lower",
				margin: 10,
				step: step,
				range: {
					min: min,
					max: max,
				},
			});

			slider.noUiSlider.on("update", function (values, handle) {
				inputInfo.textContent = `${
					Math.round(values[handle]).toLocaleString("en-GB") + " " + unit
				}`;

				inputsHidden.value = Math.round(values[handle]);
			});

			slider.noUiSlider.on("end", function () {
				changeDistance(input.value)
			});
		}
	};
	singleRangeSlider();



		$('body').on('change','[type="file"]', function(){
			$(this).parent().find('.upload-status').html(`<span>${this.files[0].name}</span>`);
			console.log($(this).parent().find('.upload-status'))
		})


	$(".jbs-filters-dropdown .jbs-filters-sub-title").on("click", function () {
		$(this).toggleClass("active");
		$(this).next().slideToggle();
	});
	$(".job-results-mobile-toggle").click(function () {
		$(".job-results-filters-wr").slideToggle();
		$(this).toggleClass("active");
	});

	function accordionFunc(elem) {
		const itemsArr = elem.querySelectorAll("[data-accordion-item]");
		console.log(itemsArr);
		itemsArr.forEach((item, i) => {

			item.addEventListener("click", () => {
				console.log(item)
				if (item.classList.contains("active")) {
					item.classList.remove("active");
				} else {
					for (let j = 0; j < itemsArr.length; j++) {
						if (itemsArr[j].classList.contains("active")) {
							itemsArr[j].classList.remove("active");
						}
					}

					item.classList.add("active");
				}
			});
		});
	}

	if (document.querySelector("[data-accordion]")) {
		const accordionsArr = document.querySelectorAll("[data-accordion]");
		accordionsArr.forEach((accordion) => {
			accordionFunc(accordion);
		});
	}


	let blogFilters = $(".blog-filters-item");

	if (blogFilters.length) {
		let bottomSlider = $(".bottom-slider");
		let activeItem = $(".blog-filters-item.active");
		bottomSlider.css({
			width: activeItem.innerWidth() + "px",
			left: activeItem.position().left + "px",
			top: activeItem.position().top + activeItem.outerHeight() + "px",
		});
		setTimeout(function(){
			bottomSlider.addClass("initialized");
		}, 300);
		blogFilters.each((idx, el) => {
			$(el).on("click", function (e) {
				activeItem = $(el);
				let currentIdx = idx;
				$(el).toggleClass("active");
				blogFilters.each((idx, el) => {
					if (idx !== currentIdx) {
						$(el).removeClass("active");
					}
				});
			});

			$(el).hover(
				function () {
					bottomSlider.css({
						width: $(this).innerWidth() + "px",
						left: $(this).position().left + "px",
						top: $(this).position().top + $(this).outerHeight() + "px",
					});
				},
				function () {
					bottomSlider.css({
						width: activeItem.innerWidth() + "px",
						left: activeItem.position().left + "px",
						top: activeItem.position().top + $(this).outerHeight() + "px"
					});
				}
			);
		});

		$(window).on("resize", function () {
			bottomSlider.css({
				width: activeItem.innerWidth() + "px",
				left: activeItem.position().left + "px",
				top: activeItem.position().top + activeItem.outerHeight() + "px",
			});
		});
	}

	const Scrollbar = window.Scrollbar;
	if (document.querySelector("[data-scrollbar]")) {
		const scrollContentsArr = document.querySelectorAll("[data-scrollbar]");
		scrollContentsArr.forEach((scrollbar) => {
			Scrollbar.init(scrollbar, {});
		});
	}

	function parallax(e) {
		this.querySelectorAll("[data-decor-parallax]").forEach((elem) => {
			const speed = elem.getAttribute("data-speed");
			const x = (window.innerWidth - e.pageX * speed) / 400;
			const y = (window.innerHeight - e.pageY * speed) / 400;

			elem.style.transform = `translateX(${x}px) translateY(${y}px)`;
		});
	}

	if (
		window.innerWidth >= 1025 &&
		document.querySelector("[data-decor-parallax]")
	) {
		document.addEventListener("mousemove", parallax);
	}

	/* Vertical Accordion */
	const verticalAccordion =
			document.getElementsByClassName("vertical-accordion")[0];
	if (verticalAccordion) {
		const verticalAccordionItemsArr = verticalAccordion.querySelectorAll(
				".vertical-accordion__item"
		);

		verticalAccordionItemsArr.forEach((item, i) => {
			item.addEventListener("click", () => {
				document
						.querySelector(".vertical-accordion__item.active")
						.classList.remove("active");

				item.classList.add("active");
			});
		});
	}

}); //enf of ready

window.initAssignmentsSlider = function initAssignmentsSlider() {
	const assignmentsSlider = document.getElementsByClassName(
			"assignments-section__slider"
	)[0];
	if (assignmentsSlider) {

		const slidesArr = assignmentsSlider.querySelectorAll('.swiper-slide');

		let loop = false;
		let loopedSlides = false;

		if(slidesArr.length > 2) {
			loop = true;
			loopedSlides = slidesArr.length * 2;
		}

		const assignmentsSwiper = new Swiper(assignmentsSlider, {
			slidesPerView: 1,
			spaceBetween: 20,
			loop: loop,
			loopedSlides: loopedSlides,
			navigation: {
				prevEl: ".assignments-nav .swiper-prev-btn",
				nextEl: ".assignments-nav .swiper-next-btn",
			},
			autoplay: {
				delay: 3000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			breakpoints: {
				901: {
					spaceBetween: 32,
				},
			},
		});

		swiperObserver(assignmentsSwiper);

		setTimeout(function () {
			const assignmentsTextArr = assignmentsSlider.querySelectorAll('.assignments-card__text');

			assignmentsTextArr.forEach(text => {
				new SimpleBar(text);
			})
		}, 100);
	}
}
initAssignmentsSlider();
